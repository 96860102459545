import { toast } from 'react-toastify';
import { Insurer } from './insurer/Index';
import { DocumentForm } from './DocumentForm';
import ReactSelect from '@src/common/ReactSelect';
import { VehicleDetails } from './VehicleDetails';
import { useEffect, useRef, useState } from 'react';
import { PersonalDetails } from './PersonalDetails';
import { useSelector, useDispatch } from 'react-redux';
import { APP_TYPE_AGENCY, DOCUMENT_CATEGORY, INS_TYPE_CTPL, REGEX_EMAIL_VALIDATION, WITHOUT_RULE_ENGINE, ENCRYPTED_FIELDS } from '@src/config/constant';
import FullPageLoader from '@src/common/FullPageLoader';
import { callServiceToCreateLead, isShowDynamicField } from '../create/FunctionList';
import { getAllAgencyList } from '@src/store/slices/agency';
import { addDocument, getDocumentListing } from '@src/store/slices/common';
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import { getAgencyUsers, saveInsuranceAgency, saveSectionList, updateInsuranceDataForAgency, uploadAndReadDocument } from '@src/store/slices/insurance';
import secureStorage from '@src/config/encrypt';
import AgencyService from '@src/services/AgencyService';
import { Nav, Tab } from 'react-bootstrap';
import { EncodetoBase64 } from '@src/config/helper';


export default function CreateInsurancePolicyAgency() {
    const commonDataRef = useRef<any>({})
    const [page, setPage] = useState<number>(1)
    const [apptype, setApptype] = useState<number>(4)
    const [documents, setDocuments] = useState<any>({})
    const [inputField, setInputField] = useState<any>({})
    const [errorField, setErrorField] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [ruleEngine, setRuleEngine] = useState<number>(1)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const [documentErrors, setDocumentErrors] = useState<any>({})
    const [sectionList, setSectionList] = useState<Array<any>>([])
    const [documentList, setDocumentList] = useState<Array<any>>([])
    const [soList, setSOList] = useState<Array<any>>([])
    const [agencyList, setAgencyList] = useState<Array<any>>([])
    const sessionDetails = secureStorage.getItem("authUserInfo")
    const userID = sessionDetails?.user_data?.user_id || null
    const [roleID, setRoleId] = useState<any>(null)
    const [orcrApiCall, setOrcrApiCall] = useState<boolean>(false)
    const [isAgencyMenuOpen, setIsAgencyMenuOpen] = useState<any>(undefined)

    const navigate = useNavigate()
    const dispatch: any = useDispatch()

    const subRoute: any = searchParams.get('step');

    const commonState = useSelector((state: any) => state.common)
    const insuranceState = useSelector((state: any) => state.insurance)

    const navigateToCreateInsurance = (params: { apptype: number, ruleEngine: number, eventKey: any }) => {
        navigate({ pathname: '/create-insurance-policy' }, { state: params })
    }

    const handleNavLinkRouting = (page: number) => {
        let { rule_engine, application_type, agency, so, role_id } = insuranceState?.createInsuranceAgency || {}
        setInputField({ agency, so })
        setRuleEngine(rule_engine)
        setApptype(application_type)
        setDocuments(documents)
        setSectionList(sectionList)
        setRefresh(!refresh)
        setRoleId(role_id)
    }

    const fetchMasterConfig = () => {
        fetchFormConfig()
    }

    const fetchFormConfig = () => {
        const config = insuranceState.formConfigAgency
        const masterDataUpdated = commonState.common

        if (config?.length) {
            let tempList: any = JSON.parse(JSON.stringify(config))
            // eslint-disable-next-line
            tempList.map((sectionItem: any) => {
                // eslint-disable-next-line
                sectionItem?.blocks?.map((blockItem: any) => {
                    // eslint-disable-next-line
                    blockItem?.fields?.map((fieldItem: any) => {
                        if (fieldItem.name === 'make_year' || fieldItem.name === 'payment_tenure' || fieldItem.name === 'payment_term') {
                            fieldItem['options'] = []
                        } else if (fieldItem.name in masterDataUpdated) {
                            if (['paid_to_whom', 'payment_method'].includes(fieldItem.name)) {
                                fieldItem['options'] = masterDataUpdated[fieldItem.name + "_agency"]
                            } else {
                                fieldItem['options'] = masterDataUpdated[fieldItem.name]
                            }
                        }
                    })
                })
            })
            setSectionList([...tempList])
            dispatch(saveSectionList(tempList))
        } else {
            navigate('/create-insurance-policy?step=insurance_form')
        }
    }

    const onRuleEngineChange = (rule: number) => {
        setRuleEngine(rule)
        fetchFormConfig()
        setPage(1)
    }

    const fetchDocumentList = () => {
        setLoading(true)
        dispatch(getDocumentListing({
            application_type_id: APP_TYPE_AGENCY
        })).then((res: any) => {
            setDocumentList(res)
        })
        setLoading(false)
    }

    const saveAndNext = async () => {
        switch (subRoute) {
            case 'upload_docs': {
                const validate = validateDocuments()
                if (!validate) break
                await checkIfORCRUploaded()
                if (page < 2) setPage(2)
                dispatch(saveInsuranceAgency({ ...inputField, ...insuranceState?.createInsuranceAgency, so: inputField['so'], agency: inputField['agency'],  rule_engine: ruleEngine, application_type: apptype, role_id: roleID }))
                navigate('/create-insurance-policy-agency?step=vehicle_details')
                break
            }

            case 'vehicle_details': {
                const validate = validateFormDetails()
                if (!validate) break
                if (page < 3) setPage(3)
                updateSectionList('vehicle_details')
                navigate('/create-insurance-policy-agency?step=insurance_quote')
                break
            }

            case 'personal_details': {
                const validate = validateFormDetails()
                if (!validate) break
                const updatedSectionList = updateSectionList('personal_details')
                submitLead(updatedSectionList)
                break
            }
        }

    }

    const checkIfORCRUploaded = async () => {
        let docs = commonDataRef.current.getData()
        if (docs?.[DOCUMENT_CATEGORY['firstSubmit']['childs']['orcr']]?.length && !orcrApiCall) {
            //call api
            await getTextFromDoc(docs[DOCUMENT_CATEGORY['firstSubmit']['childs']['orcr']])
        } else if (page < 3 && !docs?.[DOCUMENT_CATEGORY['firstSubmit']['childs']['orcr']]?.length) {
            dispatch(updateInsuranceDataForAgency({}))
        }
    }

    const getTextFromDoc = async (data: any) => {
        let formdata = new FormData()
        formdata.append('user_id', userID)

        for (let file of data) {
            formdata.append('files', file)
        }

        setLoading(true)
        //api call
        await dispatch(uploadAndReadDocument(formdata))
        setOrcrApiCall(true)
        setLoading(false)
    }

    const validateDocuments = () => {
        let docs = commonDataRef.current.getData()
        const checkConditionalDocs = checkConditionalMandatoryDocs(docs)
        const checkInputFields = validateInputFields()
        if (checkConditionalDocs || checkInputFields) return false
        setDocuments(docs)

        let list = documentList
        let errorOb: any = {}
        let isError = true;

        for (let ob of list) {
            if (ob.child.length) {
                for (let childdoc of ob.child) {
                    if (childdoc.is_required && !docs?.[childdoc.value]) {
                        isError = false;
                        errorOb[childdoc.value] = `${childdoc.label} Field is required`;
                    }
                }
            }
            else if (ob.is_required && !docs?.[ob.value]) {
                isError = false;
                errorOb[ob.value] = `${ob.label} Field is required`;
            }
        }

        setDocumentErrors((prev: any) => ({ ...prev, ...errorOb }));
        return isError;
    }

    const checkConditionalMandatoryDocs = (documents: any) => {
        let error = false
        let optionalMandatoryDocs = documentList.filter((doc: any) => doc.conditional_mandatory.length)?.map((e: any) => e.value)
        let isOptionalDocUploaded = documents ? Object.keys(documents).filter((fl: any) => optionalMandatoryDocs?.includes(+fl)) : ''

        if (optionalMandatoryDocs && optionalMandatoryDocs.length && (!isOptionalDocUploaded || !isOptionalDocUploaded.length)) {
            let msg = documentList?.filter((doc: any) => doc.conditional_mandatory.length)?.map((e: any) => e.label)?.join(' Or ')
            toast.error(`Please upload atleast 1 document of ${msg}`)
            error = true
        }
        return error
    }

    const handleAgencySOSelection = (event: any, key: string) => {
        if (key === 'so' && event?.role_id) {
            setRoleId(event.role_id)
        }

        //if option disabled, show error
        if (key === 'agency') {
            if(event?.is_disabled){

                toast.error("The selected agency is yet to be approved. Please wait for the approval process to be completed.")
                setIsAgencyMenuOpen(true) //for keeping the menu dropdown open
                return
            }else{
                //PRE SELECT AGENCY OFFICER
                if(event?.agent_id){
                    //get role id
                    const role_id = soList?.find((so:any)=> { return so?.value === event?.agent_id })?.role_id
                    setRoleId(role_id)
                    setInputField((prev: any) => {
                        return {
                            ...prev,
                            ['so']: event?.agent_id
                        }
                    })
                }
            }
        }

        const value = event.value

        if (value) setErrorField((prev: any) => ({ ...prev, [key]: null }))
        setInputField((prev: any) => {
            return {
                ...prev,
                [key]: event.value
            }
        })
        setIsAgencyMenuOpen(undefined)
    }

    const validateInputFields = () => {
        let { agency, so } = inputField
        let errorObj: any = {}
        let errorFlag = false

        if (!agency) {
            errorObj['agency'] = `Please select agent`
            errorFlag = true
        }

        if (!so) {
            errorObj['so'] = `Please select agency officers`
            errorFlag = true
        }

        if (errorFlag) {
            setErrorField((prev: any) => ({ ...prev, ...errorObj }))
        }

        return errorFlag
    }

    const validateFormDetails = () => {
        let errorFlag = true
        let fields = commonDataRef.current.getData()
        fields = subRoute === 'personal_details' ? [...fields?.personalDetails, ...fields?.residentialDetails] : fields

        if (!fields?.length) {
            errorFlag = false
        }

        fields.forEach((ele: any) => {
            const value = ele.value
            const fieldVisible = isShowDynamicField(ele, [{ fields }])
            if (fieldVisible && ele.is_required && !value) {
                ele['error_message'] = ele.error_msg
                errorFlag = false
                setRefresh(!refresh)
            }

            if (ele.name === 'phone' && value) {
                let isValidMobile = (/^[9]\d{9}$/).test(value)
                if (!isValidMobile) {
                    ele['error_message'] = 'Mobile number should start with 9 and 10 digit number'
                    errorFlag = false
                    setRefresh(!refresh)
                }
            }

            if (ele.name === 'alt_phone' && value) {
                let isValidMobile = (/^[9]\d{9}$/).test(value)
                if (!isValidMobile) {
                    ele['error_message'] = "Alternate Mobile should start with 9 and 10 digit number"
                    errorFlag = false
                    setRefresh(!refresh)
                }
            }
            if (ele.name === 'email' && value) {
                const validEmailRegex = RegExp(REGEX_EMAIL_VALIDATION);
                if (!validEmailRegex.test(value)) {
                    ele['error_message'] = 'Please enter valid email'
                    errorFlag = false
                    setRefresh(!refresh)
                }
            }

            if (ele.name === "acc_price" && value && Number(value) > 0) {
                let fmvValue = "";
                fmvValue = fields.find((data: any) => data?.name === "car_fmv")?.value
                if (fmvValue && Number(value) > Number(fmvValue)) {
                    ele['error_message'] = "Accessories price should be less than FMV of Car"
                    errorFlag = false
                    setRefresh(!refresh)
                }
            }

            if (ele.name === 'plate_number' && value) {
                let alphanumericPattern = /^[a-z0-9]+$/i;
                if (!alphanumericPattern.test(value)) {
                    ele['error_message'] = "Plate number should be alphanumeric"
                    errorFlag = false
                    setRefresh(!refresh)
                }
            }

            const insuranceType = fields.find((data: any) => data?.name === "insurance_type")?.value?.value
            if (insuranceType === INS_TYPE_CTPL && ele.name === 'plate_number') {
                let plate_number = fields.find((data: any) => data?.name === "plate_number")
                let mv_number = fields.find((data: any) => data?.name === "mv_number")
                if (!plate_number.value && !mv_number.value) {
                    plate_number['error_message'] = "Please enter Plate no or MV file no"
                    mv_number['error_message'] = "Please enter Plate no or MV file no"
                    errorFlag = false
                    setRefresh(!refresh)
                } else {
                    let alphanumericPattern = /^[a-z0-9]+$/i;
                    let isPlateNumberValid = plate_number.value ? alphanumericPattern.test(value) : true
                    let isMVNumberValid = mv_number.value ? alphanumericPattern.test(value) : true

                    //adding alphanumeric check for plate & mv number
                    plate_number['error_message'] = isPlateNumberValid ? "" : "Plate number should be alphanumeric"
                    mv_number['error_message'] =isMVNumberValid ? "" : "MV number should be alphanumeric"
                }
            }
        })

        return errorFlag
    }

    const updateSectionList = (type: string) => {
        let fields = commonDataRef.current.getData()
        let newSectionList = JSON.parse(JSON.stringify(sectionList))
        switch (type) {
            case 'vehicle_details': {
                const insurance_type = fields?.find((item: any) => item.name === 'insurance_type')?.value?.value
                const prevInsuranceType = insuranceState?.createInsuranceAgency?.insurance_type
                const vehicleDetails = newSectionList.find((item: any) => item.key === 'vehicle_details')
                const updatedFields = { ...inputField, ...insuranceState?.createInsuranceAgency, rule_engine: ruleEngine, application_type: apptype, insurance_type }

                //check for changes in the following fields
                //if change is found then reset addons to re-verify addons & plans amounts
                const changedFields = ['vehicle_type', 'make_year', 'total_fmv']
                const alteredFields = fields?.filter((item: any) => changedFields.includes(item.name))
                if (alteredFields?.length) {
                    const oldFields = vehicleDetails?.blocks?.[0]?.fields
                    alteredFields.forEach((ele: any) => {
                        const oldValue = oldFields.find((e: any) => e.name === ele.name)?.value?.value
                        const newValue = ele?.value?.value

                        if (oldValue && newValue && oldValue !== newValue) {
                            updatedFields['addon_detail'] = null
                        }
                    })
                }

                if (vehicleDetails?.blocks?.[0]?.fields) {
                    vehicleDetails.blocks[0].fields = fields
                }

                //for reseting addons
                if (prevInsuranceType && prevInsuranceType !== insurance_type) {
                    updatedFields['addon_detail'] = null
                }

                dispatch(saveInsuranceAgency({ ...updatedFields }))
                //mark auto fill true
                dispatch(updateInsuranceDataForAgency({ ...insuranceState?.insuranceData, autoFillVehicleDetails: true }))
                break
            }

            case 'personal_details': {
                const personalDetails = newSectionList.find((item: any) => item.key === 'personal_details')
                if (personalDetails?.blocks?.[0]?.fields) {
                    personalDetails.blocks[0].fields = fields.personalDetails
                }
                if (personalDetails?.blocks?.[1]?.fields) {
                    personalDetails.blocks[1].fields = fields.residentialDetails
                }
                //mark auto fill true
                dispatch(updateInsuranceDataForAgency({ ...insuranceState?.insuranceData, autoFillPersonalDetails: true }))
                break
            }

            default: {
                break
            }
        }

        setSectionList(newSectionList)
        dispatch(saveSectionList(newSectionList))
        return newSectionList
    }

    const getAllAgencyUsers = async () => {
        setLoading(true)
        const users: any = await getAgencyUsers()
        if (users?.data?.length) {
            setSOList(users.data)
        }
        setLoading(false)
    }

    const getAgencyList = async () => {
        setLoading(true)
        const response: any = await getAllAgencyList({ status: [1, 2] })
        if (response?.data?.length) {
            let data = response.data.map((ele: any) => {
                return {
                    label: ele.name + ` (ID-${ele.id}) ${ele.status !== 1 ? `(${ele.status_name})` : ''}`,
                    value: ele.id, is_disabled: ele.status !== 1 ? true : false,
                    agent_id: ele.agent_id,
                    vehicle_classification: ele?.vehicle_classification
                }
            })
            setAgencyList(data)
        }
        setLoading(false)
    }

    const submitLead = async (sections: any) => {
        setLoading(true)
        let { application_type, rule_engine, partner_id,
            partner_slug, base_issue_rate, addon_detail, agency, so, role_id, net_premium } = insuranceState?.createInsuranceAgency || {}

        if (!addon_detail?.length) {
            setLoading(false)
            return toast.error("Please select addons")
        }

        const params = callServiceToCreateLead(JSON.parse(JSON.stringify(sections)))
        const reqParam: any = {
            ...params,
            lead_type: 1, // for hierarchy
            agency_id: agency,
            user_id: so,
            role_id: +role_id,
            lead_on_behalf_user_id: userID,
            apptype: application_type,
            rule_engine,
            partner_id,
            partner_slug,
            add_on_rate: base_issue_rate,
            addon_detail,
            tenure: params.tenure || 1,
            source: 1
        }

        if(insuranceState?.insuranceData?.log_id) {
            reqParam['log_id'] = +insuranceState?.insuranceData?.log_id
        }

        //append net_premium when net_premium is edited on plans page
        if (rule_engine === WITHOUT_RULE_ENGINE) {
            reqParam['net_premium'] = +net_premium
        }

        const fields = ENCRYPTED_FIELDS;
        fields.forEach(field => {
            if (reqParam?.[field]) {
                reqParam[field] = EncodetoBase64(reqParam[field]);
            }
        });

        let result = await AgencyService.createAgencyLead(reqParam)

        if (result?.data?.data?.hashId) {
            let { hashId } = result.data.data
            dispatch(updateInsuranceDataForAgency({}))
            submitDocument({ hashId })
            dispatch(saveInsuranceAgency({}))
        }
        else {
            toast.error(result.data.message)
            setLoading(false)
        }
    }

    const submitDocument = async (params: any) => {
        let { hashId } = params

        if (Object.keys(documents).length) {
            let docArray = []
            let formdata = new FormData()
            let leadId = hashId

            formdata.append('lead_id', leadId)
            formdata.append('user_id', userID)
            formdata.append('isNewCreated', "1")
            formdata.append('apptype', "" + APP_TYPE_AGENCY)

            for (let key in documents) {
                let docType = DOCUMENT_CATEGORY['firstSubmit']['doc_type_id']

                let files = documents[key]
                for (let file of files) {
                    let docObj = {
                        document_id: key,
                        type: 'add',
                        document_type_id: +docType
                    }
                    docArray.push(docObj)
                    formdata.append('files', file)
                }
            }

            formdata.append('doc_path', JSON.stringify(docArray))
            //api call
            let uploadDocument: any = await addDocument(formdata)
            //if upload successfull
            if (uploadDocument && uploadDocument.status === 200) {
                navigate(`/details/personal-details/${leadId}`)
            } else {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        fetchMasterConfig()
        fetchDocumentList()
        getAllAgencyUsers()
        getAgencyList()
        // eslint-disable-next-line
    }, [])

    return (
        <div >
            <FullPageLoader show={loading} />
            <div className="container-fluid">
                <div className="top-heading financer-lead-heding">
                    <h1>Create Agency Insurance Application</h1>
                </div>
                <div className="lead-detail-tabs">
                    {
                        sectionList && <div className="detail-form-sec">
                            <div className="menu-tab-left-panel">
                                <div className="dealer-tab-left-panel nav">
                                    <ul>
                                        {
                                            sectionList.map((sectionItem: any, index: any) => {
                                                return (
                                                    <li key={index}>
                                                        {
                                                            ((page > index)) ?
                                                                <NavLink to={"/create-insurance-policy-agency?step=" + sectionItem['key']} onClick={(e) => { handleNavLinkRouting(index + 1) }} className={(index + 1) < page ? "completed" : ""} >
                                                                    <span className="img-type"></span>
                                                                    {sectionItem['title']}</NavLink>
                                                                :
                                                                <a href="#!" onClick={(e) => e.preventDefault()}>
                                                                    <span className="img-type"></span>
                                                                    {sectionItem['title']}
                                                                </a>

                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="route-files">
                                <div className=" insurance-create-outer relative " style={{ "display": "block" }}>
                                    <div className="loan-expect-left-panle">
                                        <div className="loan-form">
                                            <div className={`loan-form-filed-scroll ${(searchParams?.get('step') === 'upload_docs') ? `quote-scroll` : ''}`}>
                                                {
                                                    subRoute === "upload_docs"
                                                    && <div className="loan-filed-sec">
                                                        <div className="heading-dp-txt">
                                                            <span>Common Application Form</span>
                                                        </div>
                                                        <div className='common-appliction-outer m-xl-b'>
                                                            <Tab.Container id="left-tabs-example" defaultActiveKey={'first'}>
                                                                <Nav variant="pills" className={"flex-column tab-line"}>
                                                                    <div className="tab-list">
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="" onClick={() => navigateToCreateInsurance({ apptype: 2, ruleEngine, eventKey: 'first' })}>Standalone</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="" onClick={() => navigateToCreateInsurance({ apptype: 1, ruleEngine, eventKey: 'second' })}>With UCF</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="" onClick={() => navigateToCreateInsurance({ apptype: 3, ruleEngine, eventKey: 'third' })}>Renewal</Nav.Link>
                                                                        </Nav.Item>
                                                                        <Nav.Item>
                                                                            <Nav.Link eventKey="first" onClick={() => { }}>Agency</Nav.Link>
                                                                        </Nav.Item>
                                                                    </div>

                                                                </Nav>
                                                            </Tab.Container>
                                                        </div>
                                                        <div className="form-group-bx">
                                                            <div className="row">
                                                                <div className='col-md-5 search-agency-field'>
                                                                    <label className="">Search Agent* </label>
                                                                    <ReactSelect
                                                                        options={agencyList}
                                                                        value={agencyList.find((e: any) => e.value === inputField?.['agency']) || null}
                                                                        handleChange={(e: any, fieldKey: string) => { handleAgencySOSelection(e, fieldKey) }}
                                                                        placeholder="Search By name"
                                                                        fieldKey="agency"
                                                                        errorField={errorField['agency']}
                                                                        menuIsOpen={isAgencyMenuOpen}
                                                                    />
                                                                </div>
                                                                <div className='col-md-1'>&nbsp;</div>
                                                                <div className='col-md-5 search-agency-field'>
                                                                    <label className="">Search Agency Officers* </label>
                                                                    <ReactSelect
                                                                        options={soList}
                                                                        value={soList.find((e: any) => e.value === inputField?.['so'])}
                                                                        handleChange={(e: any, fieldKey: string) => { handleAgencySOSelection(e, fieldKey) }}
                                                                        placeholder="Search By name"
                                                                        fieldKey="so"
                                                                        errorField={errorField['so']}
                                                                        isDisabled={true}
                                                                    />

                                                                </div>

                                                            </div>

                                                            <div className='common-appliction-outer m-xl-b radio-btn'>
                                                                <div className='m-lg-r'>
                                                                    Application Type*
                                                                </div>

                                                                <div className="custom-control custom-checkbox col-md-3 ">
                                                                    <input checked={ruleEngine === 1} id="Individual" type="radio" className="custom-control-input " name="Buyer Type" onChange={() => onRuleEngineChange(1)} />
                                                                    <label className="custom-control-label">With Rule Engine </label>
                                                                </div>

                                                                <div className="custom-control custom-checkbox col-md-3 ">
                                                                    <input checked={ruleEngine === 2} id="Individual" type="radio" className="custom-control-input " name="Buyer Type" onChange={() => onRuleEngineChange(2)} />
                                                                    <label className="custom-control-label">Without Rule Engine </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h2>Upload Documents</h2>
                                                            </div>
                                                            <DocumentForm ref={commonDataRef} docList={documentList} setDocuments={setDocuments} errors={documentErrors} documents={documents} setOrcrApiCall={setOrcrApiCall}/>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    subRoute === 'vehicle_details'
                                                    && <VehicleDetails ref={commonDataRef} setLoading={setLoading} agencyList={agencyList}/>
                                                }
                                                {
                                                    subRoute === 'insurance_quote'
                                                    && <Insurer setLoading={setLoading} setParentPage={setPage} ref={commonDataRef} parentPage={page} agency_id={inputField?.['agency']} />
                                                }
                                                {
                                                    subRoute === 'personal_details'
                                                    && <PersonalDetails ref={commonDataRef} setLoading={setLoading} stateCityList={(commonState.stateWithCity) ? commonState.stateWithCity : []} />
                                                }
                                                {
                                                    !searchParams.get('lead_id') && searchParams.get('step') !== "insurance_quote" &&
                                                    <div className="loan-filed-sec">

                                                        <div className="col-md-12 f-right t-right">
                                                            <button className="btn-primary " onClick={() => saveAndNext()}>Save & Continue</button>
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}