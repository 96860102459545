import { useState, useEffect } from 'react'
import * as HELPER from "../../../config/helper";
import InsuranceService from 'services/InsuranceService';
import { toast } from 'react-toastify';
import FullPageLoader from '@src/common/FullPageLoader';
import { useNavigate } from 'react-router-dom';
import { APP_TYPE_RENEWAL, APP_TYPE_STANDALONE, APP_TYPE_WITH_UCF, INS_TYPE_COMPREHENSIVE, INS_TYPE_CTPL, LEAD_TYPE_WEB_APP, REGEX_DECIMAL_VALIDATION, WITH_RULE_ENGINE, WITHOUT_RULE_ENGINE,APP_AGENCY } from '@src/config/constant';
import { useSelector, useDispatch } from 'react-redux';
import { saveInsurance } from '@src/store/slices/insurance';

interface MainProps {
    params: any,
    setExtraField: any,
    applicationType: any,
    extraField: any,
    setParentPage: Function,
    backFunc: Function,
    nextFunc: Function,
    apptype: any,
    activeLoanID: any
}
const QuotesList = (props: MainProps) => {
    const [partnerList, setPatnerList] = useState<Array<Object>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showField, setField] = useState<any>({}); 
    const [inputField, setInputField] = useState<any>({ partner_id: 0, add_on_rate: 2 })
    const insuranceState = useSelector((state: any) => state.insurance);
    const navigate = useNavigate();
    const dispatch: any = useDispatch();

    useEffect(() => {
        fetchPartner()
        if (Object.keys(props.extraField).length) {
            setInputField(props.extraField)
        }
        // eslint-disable-next-line
    }, [])

    const fetchPartner = async () => {
        let reqParam = props.params;
        let params: any = {
            vehicle_usage: reqParam.vehicle_usage,
            insurance_type: reqParam.insurance_type,
            rule_engine: props.applicationType,
            total_fmv: reqParam.total_fmv,
            apptype: props.apptype,
            addon_detail: reqParam.addon_detail,
            vehicle_type: reqParam.vehicle_type
        }
        if (params.insurance_type === INS_TYPE_CTPL) {
            params['tenure'] = reqParam.tenure
            params['make_year'] = reqParam.make_year
        }else if(params.insurance_type === INS_TYPE_COMPREHENSIVE){
            params['make_year'] = reqParam.make_year
        }
        //add proposed fmv in request params
        if(props.apptype === APP_TYPE_WITH_UCF && reqParam?.proposed_fmv) params['proposed_fmv'] =  reqParam.proposed_fmv

        //if add on rate/referrer mark up is edited then use edited add_on_rate for api call
        if(props?.extraField?.add_on_rate) params['add_on_rate'] = props.extraField.add_on_rate
        if(props?.extraField?.referrer_mark_up) params['referrer_mark_up'] = props.extraField.referrer_mark_up
        
        setLoading(true);
        let result = await InsuranceService.getPartnerList(params)
        setLoading(false);
        if (result?.data?.data) {
            if ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(props.apptype)) checkDiscount(result.data.data)
            setPatnerList(result.data.data);
        } else {
            toast.error(result?.data?.errors)
            props.setParentPage(1)
            navigate('/create-insurance-policy?step=insurance_form');
        }
    }

    const getAddonDetail = async (partner_id: number, ob: object) => {

        setLoading(true)
        let reqParam = props.params;
        let loanData = insuranceState?.activeLoanID
        let params: any = {
            partner_id: partner_id,
            vehicle_usage: reqParam.vehicle_usage,
            insurance_type: reqParam.insurance_type,
            rule_engine: props.applicationType,
            total_fmv: reqParam.total_fmv,
            lead_type: props.apptype === APP_TYPE_WITH_UCF ? loanData?.lead_type : LEAD_TYPE_WEB_APP,
            role_id: (props.apptype === APP_TYPE_WITH_UCF && loanData?.lead_type === 1) ? 6 : props.apptype !== APP_TYPE_WITH_UCF ? 3 : 0,
            apptype: props.apptype,
            addon_detail: reqParam.addon_detail,
            vehicle_type: reqParam.vehicle_type,
            mfg_year: reqParam.make_year
            //  "referrer_mark_up":2300 
        }
        if (params.insurance_type === INS_TYPE_CTPL) {
            params['tenure'] = reqParam.tenure
        }
        if(insuranceState.application_type_id === APP_AGENCY){
            params["agency_id"]=insuranceState?.agency_id  
        }

        //handle proposed fmv
        if (props.apptype === APP_TYPE_WITH_UCF) {
            params.proposed_fmv = reqParam.proposed_fmv || 0
        }
        
        params = Object.assign(params, ob);
        let result = await InsuranceService.getAddonDetails(params)
        setLoading(false)
        if (result?.data?.data && Object.keys(result?.data?.data)?.length) {
            let partnerUpdated = partnerList.map((ob: any) => {
                return (+ob.id === +partner_id) ? Object.assign(ob, result.data.data) : ob
            })
            setPatnerList(partnerUpdated)
            let keys = ['referrer_mark_up', 'net_premium', 'discount']
            let checkIfKeyExists = keys.some((ele: any) => Object.keys(ob).includes(ele))
            if(checkIfKeyExists) {
                //update input fields after successful api call 
                setInputField(ob)
                props.setExtraField(ob)
            }
        } else {
            toast.error(result.data.message);
        }
    }

    const changeHandler = (partner_id: number, add_on_rate: number, slug: string) => {
        //find netpremium
        let grossPremium: any = partnerList.find((partner: any) => partner.id === partner_id)
        grossPremium = grossPremium ? grossPremium.gross_premium : 0

        setInputField({ partner_id, add_on_rate })
        props.setExtraField({ partner_id, add_on_rate, partner_slug: slug }) 

        //update net premium in state
        dispatch(saveInsurance({ 
            ...insuranceState.createInsurance, 
            gross_premium: grossPremium,
        }));
    }
    const setInputValue = (event: any, partner_id: number) => {
        let name = event?.target?.name
        let value = event.target.value;
        const regex = REGEX_DECIMAL_VALIDATION
        const allowDecimalFor = ['net_premium', 'referrer_mark_up', 'discount']
        const isDecimalEnable = allowDecimalFor.includes(name)

        if (!isDecimalEnable || ((isDecimalEnable && (regex.test(value))))) {
            //replace leading 0's
            if (value && isDecimalEnable) value = value.toString().replace(/^0+/, '')
            event.target.value = value

            if (isDecimalEnable) {
                let partner: any = partnerList?.find((e: any) => e.id === partner_id)

                let fmv = partner?.proposed_fmv || partner?.total_fmv
                //return if entered value is greator than total fmv
                if (fmv && +event.target.value >= fmv) return
            }

            let ob = { ...inputField, [event.target.name]: +value }

            //if netpremium is changed, update discount to 0
            if(props.apptype === APP_TYPE_STANDALONE && name === 'net_premium') {
                ob['discount'] = 0
            }

            //update input 
            if (!isDecimalEnable || (name === 'discount' && value.toString()?.length < 2 && value !== '')) {
                setInputField(ob)
                props.setExtraField(ob)
            }
            //update inputfield 
            //when name is not discount or if name is discount then it should be a double digit or 0
            if (name !== 'discount' || (name === 'discount' && (!value || value?.toString()?.length > 1))) {
                getAddonDetail(partner_id, ob);
            }
        }
    }

    const handleBtnClick = (type: string, itemOb: any) => {
        let rate = type === 'add' ? inputField.add_on_rate + itemOb.basic_rate_incement : inputField.add_on_rate - itemOb.basic_rate_incement; 

        let value = +parseFloat(rate).toFixed(2); 

        if (+value < itemOb.basic_rate_min) {
            toast.error("Add on rate should not be less than "+itemOb.basic_rate_min)
        }else  if (+value > itemOb.basic_rate_max) {
            toast.error("Add on rate should not be greater than "+itemOb.basic_rate_max)
        }else {
            getAddonDetail(inputField.partner_id, { add_on_rate: value });
            setInputField((prev: any) => {
                let obj = { ...prev }
                if (prev['discount'] && prev['discount'] > 0) obj['discount'] = 0 //on rate increment/decrement reset discount
                return { ...obj, add_on_rate: value }
            })
            let extraFs = { ...props.extraField, add_on_rate: value }
            if (extraFs['discount'] && extraFs['discount'] > 0) extraFs['discount'] = 0 //on rate increment/decrement reset discount
            props.setExtraField({ ...extraFs })
        }
    }

    /**
     * Checks if entered discount is valid, if not then resets it to 0
     * @param partners List of partners
     */
    const checkDiscount = (partners: any) => {
        let { gross_premium } = insuranceState.createInsurance
        let partner = partners.find((p: any) => p.id === props.extraField.partner_id)

        //if previous net premium and current net premium does not match, then reset discount
        if (partner && partner.gross_premium !== gross_premium) {
            setInputField((prev: any) => ({ ...prev, discount: 0 }))
            props.setExtraField({ ...props.extraField, discount: 0 })
            //update net premium in state
            dispatch(saveInsurance({ ...insuranceState.createInsurance, gross_premium: partner.gross_premium }));
        }
    }

    let actNature=props?.params?.addon_detail?.filter((el:any)=>el.id===6);
  
    return (
        <div className="loan-filed-sec">
            <FullPageLoader show={loading} />
            <div className="heading-dp-txt loan-btn-heading">
                <span>Insurance Loan Quotes</span>
                <div>
                    <button className="btn-line m-sm-r" onClick={() => props.backFunc()}>Back </button>
                    <button className="btn-primary " onClick={() => props.nextFunc()}>Save & Next</button> 
                </div>
            </div>

            {
                partnerList.length ? partnerList.map((item: any, index) => {
                    return (<div className={index === 0 ? `financer-quote-data p-t-70` : `financer-quote-data`} key={index}>


                        <div className="top-heading financer-lead-heding insurace-quote-heading">
                            <h1>{item.name}</h1>
                            <div className="custom-control custom-checkbox">
                                <input id="partner_id" type="radio" className="custom-control-input " name="partner_id" onChange={() => changeHandler(item.id, item.basic_rate_default, item.slug)} checked={+inputField.partner_id === +item.id} />
                                <label className="custom-control-label"></label>
                            </div>
                        </div>

                        <div className='insure-make-varint'>
                            <div className='insurance-img'>
                                <img src={item.logo} alt='' style={{width:"150px"}} /></div>

                            <div className='insure-varint-make-detail'>
                                <span>{props.params.make_name + " " + props.params.model_name + " " + props.params.version_name}</span>
                                <ul>
                                    <li>{props.params.make_year}</li>
                                    {
                                        props.apptype === APP_TYPE_STANDALONE
                                            ?
                                            <>
                                                <li>{(props.params.transmission) ? props.params.transmission : 'NA'}</li>
                                                <li>{(props.params.fuel_type) ? props.params.fuel_type : 'NA'}</li>
                                            </>
                                            : null
                                    }

                                </ul>
                            </div>
                        </div>

                        <div className='insurance-detail-outer'>
                            <ul>
                           
                                {
                                    (inputField.partner_id === item.id) && (props.params.insurance_type === INS_TYPE_COMPREHENSIVE && props.applicationType === WITH_RULE_ENGINE)
                                        ?
                                        <li>
                                           <span> {"Add on Rate(Incremental by "+item.basic_rate_incement+"%)"}</span>

                                            <button className="ic-remove" onClick={() => handleBtnClick('min', item)}></button>
                                            <button className="ic-add" style={{ marginLeft: '10px', marginRight: '10px' }} onClick={() => handleBtnClick('add', item)}></button>
                                        </li>
                                        : null
                                }
                               
                            {
                                props.params?.insurance_type === 1 ?
                                    <li>
                                        <span>Act of Nature</span>
                                        <label>{(actNature?.length>0)?`Included`:`Not Included`}</label>
                                    </li> : null
                            }
                            <li>
                                <span>FMV of Car</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.total_fmv)}`}>  {HELPER.addCurrencyFormatting(item.total_fmv)}</label>
                            </li>

                            {
                                item.proposed_fmv ?
                                    <li>
                                        <span>Proposed FMV of Car</span>
                                        <label title={`${HELPER.addCurrencyFormatting(item.proposed_fmv)}`}>  {HELPER.addCurrencyFormatting(item.proposed_fmv)}</label>
                                    </li>
                                : null

                            }

                            <li>
                                <span>Own Theft Damage</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.own_theft_damage)}`}>  {HELPER.addCurrencyFormatting(item.own_theft_damage)}</label>
                            </li>

                        

                            {
                                item.bi_coverage &&
                                <li>
                                    <span>BI Coverage Amount</span>
                                    <label title={`${HELPER.addCurrencyFormatting(item.bi_coverage)}`}>  {HELPER.addCurrencyFormatting(item.bi_coverage)}</label>
                                </li>
                            }
                            
                            <li>
                                <span>BI Premium</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.bi_amt)}`}>  {HELPER.addCurrencyFormatting(item.bi_amt)}</label>
                            </li>
                            {
                                item.hasOwnProperty('pa_amt') ?
                                    <>  
                                        {
                                            item.pa_coverage && 
                                            <li>
                                                <span>PA Coverage Amount</span>
                                                <label title={`${HELPER.addCurrencyFormatting(item.pa_coverage)}`}>  {HELPER.addCurrencyFormatting(item.pa_coverage)}</label>
                                            </li>
                                        }
                                        
                                        {
                                            item?.total_coverage_amount_pa && 
                                            <li>
                                                <span>Total Coverage Amount (PA)</span>
                                                <label title={`${HELPER.addCurrencyFormatting(item?.total_coverage_amount_pa)}`}>  {HELPER.addCurrencyFormatting(item?.total_coverage_amount_pa)}</label>
                                            </li>
                                        }
                                        <li>
                                            <span>PA Premium</span>
                                            <label title={`${HELPER.addCurrencyFormatting(item.pa_amt)}`}>  {HELPER.addCurrencyFormatting(item.pa_amt)}</label>
                                        </li> 
                                    </>
                                    : null
                            }
                            {
                                item.hasOwnProperty('pd_amt') ?
                                        <>
                                            {
                                                item.pd_coverage &&
                                                <li>
                                                    <span>PD Coverage Amount</span>
                                                    <label title={`${HELPER.addCurrencyFormatting(item.pd_coverage)}`}>  {HELPER.addCurrencyFormatting(item.pd_coverage)}</label>
                                                </li>
                                            }
                                            <li>
                                                <span>PD Premium</span>
                                                <label title={`${HELPER.addCurrencyFormatting(item.pd_amt)}`}>  {HELPER.addCurrencyFormatting(item.pd_amt)}</label>
                                            </li>
                                        </>
                                    : null
                            }
                            {
                                (item.hasOwnProperty('tra_amt')) ?
                                    <li>
                                        <span>TRA Premium</span>
                                        <label title={`${HELPER.addCurrencyFormatting(item.tra_amt)}`}>  {HELPER.addCurrencyFormatting(item.tra_amt)}</label>
                                        </li>: null
                            }
                            {
                                (item.hasOwnProperty('rscc_amount')) ?
                                    <li>
                                        <span>RSCC Premium</span>
                                        <label title={`${HELPER.addCurrencyFormatting(item.rscc_amount)}`}>  {HELPER.addCurrencyFormatting(item.rscc_amount)}</label>
                                        </li>: null
                            }
                            {
                                props.params?.insurance_type === 1 ? 
                                    <li>
                                        {/* <span>{(item.hasOwnProperty('tra_amt')) ? 'BI + PD + PA + TRA' : 'BI + PD + PA'}</span> */}
                                        <span>{item.hasOwnProperty('rscc_amount') ? 'BI + PD + PA + RSCC' : 'BI + PD + PA'}</span>
                                        <label title={`${HELPER.addCurrencyFormatting(item.bi_pd_pa)}`}>  {HELPER.addCurrencyFormatting(item.bi_pd_pa)}</label>
                                    </li> : null
                            }

                            <li>
                                <span>Net Premium</span>
                                {
                                    showField[item.id] && showField[item.id] === 'net_premium' ?
                                        <label>
                                            <fieldset>
                                                <div className="material">
                                                    <input type="number" name={showField[item.id]} className="form-input input-edit" value={(+inputField.partner_id === +item.id && inputField.net_premium) ? inputField.net_premium : item.net_premium} onChange={(e) => setInputValue(e, item.id)} onBlur={() => setField({})} />
                                                </div>
                                            </fieldset>
                                        </label>
                                        :
                                        <label>  {HELPER.addCurrencyFormatting((+inputField.partner_id === +item.id && inputField.net_premium) ? inputField.net_premium : item.net_premium)}
                                            {+inputField.partner_id === +item.id && props.params.insurance_type === INS_TYPE_COMPREHENSIVE && props.applicationType === WITHOUT_RULE_ENGINE &&
                                                <i className='ic-edit pointer edit-icon' onClick={() => setField({ [item.id]: 'net_premium' })}></i>
                                            }
                                        </label>
                                }
                            </li>

                        

                            
                       
                            <li>
                                <span>VAT</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.vat)}`}>  {HELPER.addCurrencyFormatting(item.vat)}</label>
                            </li>
                            <li>
                                <span>Stamp Duty Govt</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.stamp_duty)}`}>  {HELPER.addCurrencyFormatting(item.stamp_duty)}</label>
                            </li>
                            <li>
                                <span>Local Govt Taxes</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.local_govt_taxes)}`}>  {HELPER.addCurrencyFormatting(item.local_govt_taxes)}</label>
                            </li>
                            {
                                item.tariff > 0 &&  
                                <li>
                                    <span>Tariff</span>
                                    <label title={`${HELPER.addCurrencyFormatting(item.tariff)}`}>  {HELPER.addCurrencyFormatting(item.tariff)}</label>
                                </li>
                            }

                            <li>
                                <span>Gross Premium</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.gross_premium)}`}>  {HELPER.addCurrencyFormatting(item.gross_premium)}</label>
                            </li>
                            { item?.participation_fees &&
                            <li>
                                <span>Participation Fees</span>
                                <label title={`${HELPER.addCurrencyFormatting(item.participation_fees)}`}>  {HELPER.addCurrencyFormatting(item.participation_fees)}</label>
                            </li>
                                }
                                {
                                    (props.apptype !== APP_TYPE_WITH_UCF) ?
                                        <li>
                                            <span>Discount to Customer</span>
                                            {
                                                showField[item.id] && showField[item.id] === 'discount' ?
                                                    <label>
                                                        <fieldset>
                                                            <div className="material">
                                                                <input type="number" name={showField[item.id]} className="form-input input-edit" value={(inputField.partner_id === item.id && (inputField.discount || inputField.discount === 0)) ? inputField.discount : 0} onChange={(e) => setInputValue(e, item.id)} onBlur={() => {
                                                                    setField({})
                                                                    if(inputField.discount !== 0 && inputField.discount < 10) {
                                                                        toast.error("Please enter a valid discount amount")
                                                                        setInputField((prev: any) =>  ({...prev, discount: item.discount}))
                                                                    }
                                                                }} />
                                                            </div>
                                                        </fieldset>
                                                    </label>
                                                    :
                                                    <label>  {HELPER.addCurrencyFormatting((inputField.partner_id === item.id && (inputField.discount || inputField.discount === 0)) ? inputField.discount : 0)}
                                                        {
                                                            inputField.partner_id === item.id &&
                                                            <i className='ic-edit pointer edit-icon' onClick={() => setField({ [item.id]: 'discount' })}></i>
                                                        }

                                                    </label>
                                            }
                                        </li> : null
                                }

                                {
                                    (item.hasOwnProperty('referrer_mark_up')) ?
                                        <li>
                                            <span>Referrer Mark Up</span>
                                            {
                                                showField[item.id] && showField[item.id] === 'referrer_mark_up' ?
                                                    <label>
                                                        <fieldset>
                                                            <div className="material">
                                                                <input type="number" name={showField[item.id]} className="form-input input-edit" value={(inputField.partner_id === item.id && inputField.referrer_mark_up) ? inputField.referrer_mark_up : item.referrer_mark_up} onChange={(e) => setInputValue(e, item.id)} onBlur={() => setField({})} />
                                                            </div>
                                                        </fieldset>
                                                    </label>
                                                    :
                                                    <label>  {HELPER.addCurrencyFormatting((inputField.partner_id === item.id && inputField.referrer_mark_up) ? inputField.referrer_mark_up : item.referrer_mark_up)}
                                                        {
                                                            inputField.partner_id === item.id &&
                                                            <i className='ic-edit pointer edit-icon' onClick={() => setField({ [item.id]: 'referrer_mark_up' })}></i>
                                                        }

                                                    </label>
                                            }
                                        </li> : null
                                }
                                {
                                    props.apptype === APP_TYPE_WITH_UCF
                                        ?
                                        <li>
                                            <span>Final Premium</span>
                                            <label title={`${HELPER.addCurrencyFormatting(item.final_premium)}`}>  {HELPER.addCurrencyFormatting(item.final_premium)}</label>
                                        </li>
                                        : null
                                }
                                {
                                    (props.apptype !== APP_TYPE_WITH_UCF && item.total_premium)
                                        ?
                                        <li>
                                           <span>Total Premium</span>
                                           <label title={`${HELPER.addCurrencyFormatting(item.total_premium)}`}>
                                               {inputField?.discount ? HELPER.addCurrencyFormatting(((item.gross_premium + item?.referrer_mark_up || 0) - inputField.discount).toFixed(2)) : HELPER.addCurrencyFormatting((item.final_premium))}
                                           </label>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>

                        

                    </div>)
                }) : null
            } 

        </div>
    )
}

export default QuotesList