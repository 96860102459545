import { useState,useEffect } from 'react';
import {useParams } from 'react-router-dom';
import LoanExpectedRightPanel from './LoanExpectedRightPanel'
import Modal from '../../../common/ModalPopup';
import InsuranceEditForm from './InsuranceEditForm';
import AddonForm from './AddonForm' 
import QuotesDetail from './QuotesDetail' 
import InsuranceService from 'services/InsuranceService';  
import {callServiceToCreateLead,getAddonList,isShowDynamicField} from '../create/FunctionList'
import { useSelector,useDispatch } from 'react-redux';
import FullPageLoader from '@src/common/FullPageLoader';
import { downloadLoanQuotationPdf, fetchLeadData,updateLeadDetailsInStore, fetchQuoteDetails,getLeadEmailTemplates } from "@src/store/slices/insurance";
import ShareQuotesDetail from './ShareQuotesDetail';
import {STATUS_SUB_STATUS, ROLE_FEATURES, APP_TYPE_STANDALONE, APP_TYPE_WITH_UCF, API_TYPES, INS_TYPE_CTPL, ALLOW_LEAD_SOURCE,MARKETING_LEAD_SOURCE,DSA_LEAD_SOURCE, INSTALLMENT, APP_TYPE_RENEWAL, ENCRYPTED_FIELDS, APP_TYPE_AGENCY, WITH_RULE_ENGINE,APP_AGENCY} from '@src/config/constant';
import { toast } from 'react-toastify';
import * as HELPER from "@src/config/helper";
import { getMMVList } from '@src/store/slices/common';
import AgencyService from '@src/services/AgencyService';
  
const LoanExpected = () => {
    const queryParams = useParams();
    const [displayBlock, setDisplayBlock]: any = useState("SUMMARY");
    const [showApproveBtn, setShowApproveBtn]= useState<boolean>(false);
    const [addons, setAddons]: any = useState(false);
    const [addonList,setAddonList] = useState<any>([]);
    const [formData,setFormData] = useState([]);
    const [leadDetails,setLeadDetails] = useState({});
    const [loanQuote,setLoanQuote] = useState<any>(null);
    const allState = useSelector((state: any) => state);
    const [loading, setLoading] = useState<boolean>(false)
    const [value, setValue] = useState(1)
    const [showShareLoanQuote, setShowShareLoanQuote] = useState<boolean>(false)
    const dispatch: any = useDispatch();
    const [addonCount, setAddonCount] = useState(0);
    const [insuranceType, setInsuranceType] = useState<any>('');
    const [financeType, setFinanceType] = useState<any>('');
    const [vehicleType, setVehicleType] = useState<any>('');
    const [vehicleusage, setVehicleUsage] = useState<any>('');

    const insuranceState =  (allState.insurance && allState.insurance.leadDetail) ? allState.insurance.leadDetail: null;
    const insuranceQuotesState = (insuranceState)? insuranceState.quotes: null;
    const vehicleTypeList = allState?.common?.common?.vehicle_type || [];
    const insuranceStateStore = useSelector((state: any) => state.insurance);

    useEffect(() => {
        fetchQuotesData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
       
        if(!insuranceType && insuranceState && insuranceState.insurance_type_id){
            setInsuranceType(insuranceState.insurance_type_id);
        }
        if(!financeType && insuranceState && insuranceState.is_under_financing){
            setFinanceType(+insuranceState.is_under_financing);
        }
        if(!vehicleusage && insuranceState && insuranceState.vehicle_id){
            setVehicleUsage(+insuranceState.vehicle_id);
        }
        if(!vehicleType && insuranceState && insuranceState.vehicle_type_id){
            setVehicleType(+insuranceState.vehicle_type_id);
        }

    // eslint-disable-next-line  
    },[allState.insurance, allState.insurance.leadDetail]);

    const fetchQuotesData = async () => {
        setLoading(true)
        let result: any = await fetchQuoteDetails({ lead_id: queryParams.id })

        if (result && result?.status === 200) {
            let leadDetails = allState?.insurance?.leadDetail
            dispatch(updateLeadDetailsInStore({ ...leadDetails, ...result?.data }))
        }
        setLoading(false)
    }

    const toggleswitch = (type: any) => {
        setLoading(true)
        if(type==='EDITVIEW'){
            let params ={
                rule_engine: insuranceState.rule_engine_id, 
                vehicle_usage : insuranceState.vehicle_id,
                apptype: insuranceState.application_type_id,
                vehicle_type: insuranceState.vehicle_type_id
            }
            dispatch(getMMVList(params))
            .then((res: any) => setLoading(false)) 
            .catch((err: any) => setLoading(false))

            if(insuranceState?.application_type_id === APP_AGENCY){

                InsuranceService.getPartnerList({...params, agency_id: insuranceState?.agency_id, insurance_type: insuranceState?.insurance_type_id, total_fmv: insuranceState?.total_fmv, make_year: insuranceState?.make_year,tenure:insuranceState?.tenure}).then(res=>{
                    if(res?.data?.data?.[0]?.id !== insuranceState?.partner_id){
                        // toast.success("Partner has been updated")
                        // let leadDetails = allState?.insurance?.leadDetail

                        dispatch(updateLeadDetailsInStore({ ...insuranceState, partner_id: res?.data?.data?.[0]?.id, partner_slug: res?.data?.data?.[0]?.slug }))

                    }
                })
            }
            
        }
       
        setAddonCount(insuranceState?.addon_detail?.length);
        setDisplayBlock(type)
        setVehicleType(insuranceState?.vehicle_type_id)
        setAddonList([])

        if(type !== "EDITVIEW") setLoading(false)
    } 

    const showHideAddons = (action: any) => {
        setAddons(action)
        document.body.classList.remove("overflow-hidden");

        if (action) {
            fetchAddons(); 
        }
    }

    const showModalFinancerEmail = () => {
        setShowShareLoanQuote(!showShareLoanQuote)
    }

    const fetchAddons = async (insuranceid=null) => { 
        setLoading(true) 
        let params = { 
            rule_engine:insuranceState?.rule_engine_id, 
            vehicle_type:(vehicleType)?vehicleType:insuranceState?.vehicle_type_id, 
            apptype:insuranceState?.application_type_id, 
            insurance_type:(insuranceid)?insuranceid:insuranceType,
            make_year:insuranceState?.make_year,
            source: (insuranceState?.application_type_id === APP_TYPE_AGENCY) ? "agency_app" : undefined
        }
        let result = await InsuranceService.getAddonList(params) 
        setLoading(false)
        if (result?.data?.data) {
            for(let data of result.data.data){
                if(data.id===3){
                    data.extra_seat_capacity='';
                }
                let addonField = getAddonList([],addonList);
                let addonData=(addonField && addonField.length>0)?addonField:insuranceState?.addon_detail;
                
                if(+insuranceType===+insuranceState?.insurance_type_id && +vehicleusage===+insuranceState?.vehicle_id && +vehicleType=== +insuranceState?.vehicle_type_id ){
                
                    let addonIds = addonData.filter((item:any)=>item.id===data.id);
                    if(addonIds.length>0){
                        data.coverage_amt=addonIds[0].coverage_amount;
                        data.premium_amount = addonIds[0].premium_amount;
                        data.is_selected=true;
                        if(addonIds[0].id===3){
                            data.seat_capacity=addonIds[0].seat_capacity;
                            data.extra_seat_value=addonIds[0].extra_seat_capacity;
                        }
                    }else{
                        data.is_selected=false;   
                    }
                }
                if(data.id===6){
                    if(+insuranceType ===1 && +financeType===1){
                        data.is_required=true;
                        data.is_selected=true;
                    }
                }
               
            }
            let resultcount=result?.data?.data?.filter((item:any)=>item.is_selected===true);
            setAddonList([...result.data.data]);
            setAddonCount(resultcount.length) 
        } 
    }
    const isValidAllField = (sectionItem: any) => {
        // when sectionItem.blocks is undefined handle that case,
        if (!sectionItem || !sectionItem.blocks) {
            return false;
        }

        let flag = true
        let blockList = sectionItem['blocks'] as []
        // eslint-disable-next-line  
        blockList.map((blockItem: any) => {
            // eslint-disable-next-line  
            blockItem.fields.map((fieldItem: any) => { 
                let isValue = false;
                if(['make','model','version'].includes(fieldItem.name)){
                    isValue =  (fieldItem?.value || fieldItem?.value ===0) ? true : false; 
                }else{
                    isValue = fieldItem?.value ? true: false 
                } 


                if(['financier'].includes(fieldItem.name)){
                    isValue = fieldItem?.value === "" ? false: true 
                }
                if (fieldItem.type === 'select_addon') {
                    //
                } else if (isShowDynamicField(fieldItem, blockList, { apptype: insuranceState?.application_type_id }) && fieldItem.is_required && (!isValue) ) { 
                    
                    if(fieldItem?.name === "financier_name"){
                        
                        let filterItemParent = blockItem?.fields?.filter((item: any) => item.name === "financier")?.[0]
                        if(filterItemParent?.value === 0 || filterItemParent?.value?.value === 0){
                            fieldItem['error_message'] = fieldItem.error_msg
                            flag = false
                            setValue(value + 1)
                        }
                    }else{

                        
                        fieldItem['error_message'] = fieldItem.error_msg
                        flag = false
                        setValue(value + 1)
                    }
                }

            })
        })

        return flag
    }

    const setAddon = (popupAddons: any) => {
        let addonField = getAddonList([],popupAddons);
        setAddonList([...popupAddons]);

        setAddonCount(addonField?.length);
    }

    const calculateInsurance = async (formData:any,reqparams:any={})=>{
        if (isValidAllField(formData[0])) {
            setLoading(true) 
            let response = callServiceToCreateLead(formData, { type: 'edit' });
            let addonField = getAddonList([], addonList);

            let params:any = {
                partner_id:insuranceState?.partner_id,
                partner_slug:insuranceState?.partner_slug,
                lead_type:insuranceState?.lead_type ,
                role_id:insuranceState?.role_id,
                rule_engine:insuranceState?.rule_engine_id,
                apptype:insuranceState?.application_type_id ,
                vehicle_usage: response.vehicle_usage,
                insurance_type: response.insurance_type,
                total_fmv: response.total_fmv,
                lead_id: +insuranceState?.lead_id,
                vehicle_type: vehicleType ? vehicleType : insuranceState?.vehicle_type,
                version_id: insuranceState?.version,
                mfg_year: response.make_year || insuranceState?.mfg_year
            } 

            //CHECK IF PATNER NOT FOUND THEN FETCH PARTNER
            if(insuranceState.application_type_id === APP_TYPE_AGENCY) {
                let latestPartnerCheck = await InsuranceService.getPartnerList({
                    rule_engine: insuranceState.rule_engine_id, 
                    vehicle_usage : response.vehicle_usage,
                    apptype: insuranceState.application_type_id,
                    vehicle_type: response.vehicle_type, 
                    agency_id: insuranceState?.agency_id, 
                    insurance_type: insuranceState?.insurance_type_id, 
                    total_fmv: insuranceState?.total_fmv, 
                    make_year: insuranceState?.make_year,
                    tenure:insuranceState?.tenure})

                    //UPDATE NEW PARTNER
                    if(latestPartnerCheck?.data?.data?.[0]?.id !== insuranceState?.partner_id){
                        params['partner_id']    = latestPartnerCheck?.data?.data?.[0]?.id;
                        params['partner_slug']  = latestPartnerCheck?.data?.data?.[0]?.slug;

                        dispatch(updateLeadDetailsInStore({ ...insuranceState, partner_id: latestPartnerCheck?.data?.data?.[0]?.id, partner_slug: latestPartnerCheck?.data?.data?.[0]?.slug }))

                    }
            }

            
            if(response && response?.tenure){
                params["tenure"]=response?.tenure;   
            }
        
            if(insuranceState && insuranceState?.referrer_mark_up){
                params["referrer_mark_up"]=insuranceState?.referrer_mark_up;   
            }
            // params["net_premium"]=(reqparams && reqparams["net_premium"])?reqparams["net_premium"]:insuranceQuotesState.net_premium;   //OLD WAY
            if(reqparams && (reqparams["net_premium"] || reqparams["net_premium"] === 0)){ //SEND NET PREMIUM ONLY IF SELECTED
                params["net_premium"]= reqparams["net_premium"]
            } 
            if(reqparams && (reqparams["referrer_mark_up"] || reqparams["referrer_mark_up"] === 0)){ //SEND REFERRER MARK UP ONLY IF SELECTED
                params["referrer_mark_up"]= reqparams["referrer_mark_up"]
            } 
            if(addonField.length>0){
                params["addon_detail"]=addonField;
            }else{
                params["addon_detail"]=insuranceState?.addon_detail  
            } 

            if (reqparams.add_on_rate) {
                params['add_on_rate'] = reqparams.add_on_rate
                if (params?.['rule_engine'] === WITH_RULE_ENGINE) {
                    delete params.net_premium
                }
            }
            
            //for handling discount
            if([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState?.application_type_id)) {
                params.last_premium = loanQuote?.gross_premium || insuranceState?.gross_premium
                params.discount = (reqparams.discount || reqparams.discount === 0) ? reqparams.discount : ((loanQuote?.discount || loanQuote?.discount === 0) ? loanQuote.discount : insuranceState?.discount)  
            }

            //handle proposed fmv
            if(insuranceState.application_type_id === APP_TYPE_WITH_UCF) {
                params.proposed_fmv = response.proposed_fmv || 0
            }
            if(insuranceState.application_type_id === APP_AGENCY){
                params["agency_id"]=insuranceState?.agency_id  
            }

            let result = await InsuranceService.getAddonDetails(params);
            setLoading(false)
            if(result?.data?.status===200){
                let updatedData = {...result.data.data}
                setLeadDetails(response);
                setFormData(formData);
                setLoanQuote({...updatedData})
                setDisplayBlock("SUMMARY");
                setShowApproveBtn(true); 
            }else{
                toast.error(result?.data?.message);
            }
            
             
        }
    } 

    // function of validation RequiredField
    const validationRequiredField = () => { 
        const {payment_term_id,plate_no,insurance_type_id,mv_number,pay_tenure,expected_installment, lead_source, referrer_mobile, referrer_name,lead_sub_source, application_type_id, paid_by_customer, premium_amount, premium_amount_date } = insuranceStateStore?.leadDetail;
        // expected date validation
        if (pay_tenure && application_type_id === APP_TYPE_STANDALONE && payment_term_id === INSTALLMENT) {
            const months = parseInt(pay_tenure.split(' ')[0], 10);
            // Check each installment date
            for (let i = 1; i <= months; i++) {
                if (!expected_installment|| (expected_installment && !expected_installment[i])) {
                    toast.error("All fields are required.")
                    return false;
                }
            }
        }

        let checkKeysGlobal = ['customer_name', 'per_house_no', 'per_street', 'per_region', 'per_city', 'per_barangay', 'per_postal_code', 'fuel_type', 'is_under_financing', 'vehicle_seat']

        let isValid = true

        if(application_type_id !== APP_TYPE_AGENCY) {
            checkKeysGlobal = [...checkKeysGlobal, 'payment_method_id', 'payment_term_id',  'lead_source', 'mobile', 'email', 'insurance_policy_exists']
        } else {
            checkKeysGlobal = [...checkKeysGlobal, 'per_full_address']
        }

        for(let key of checkKeysGlobal) {
            if(!insuranceStateStore?.leadDetail?.[key]) {
                isValid = false
                break
            }
        }

        //|| ([APP_TYPE_AGENCY].includes(application_type_id) && +paid_from_agency === 1 && (!premium_amount || (!pay_tenure && !premium_amount_date))
        if (
            !isValid || (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(application_type_id) && +paid_by_customer === 1 && (!premium_amount || (!pay_tenure && !premium_amount_date))) )
        ) {
            toast.error("All fields are required. Please fill in all the Customer Details.");
            return;
        }
        // for CTPL plate no or mv number required
        if(+insurance_type_id === INS_TYPE_CTPL && (!plate_no && !mv_number)){
            toast.error("All fields are required. Please fill plate no or mv number");
            return false;
        }
        // Check if any of the required fields are empty
        if (ALLOW_LEAD_SOURCE.includes(lead_source) &&  ((!referrer_mobile || referrer_mobile.trim() === "") && (!referrer_name || referrer_name.trim() === ""))) {
            toast.error("All fields are required. Please fill in all the Other Details.");
            return false;
        }
        if(+lead_source === DSA_LEAD_SOURCE && (!referrer_name || referrer_name.trim() === "" )){
            toast.error("All fields are required. Please fill in all the Other Details.");
            return false;
        }
        if(+lead_source === MARKETING_LEAD_SOURCE && !lead_sub_source){
            toast.error("All fields are required. Please fill in all the Other Details.");
            return false;
        }
        return true;
    };

    const saveInsurance = async ()=>{
        // check validation all required input field is filled or not
        if (insuranceState) {
            const isValid = await validationRequiredField()
            if (!isValid) {
                return false;
            }
        }
        let params:any={
            lead_id:insuranceState.lead_id,
            insurance_type:insuranceState.insurance_type_id,
            rule_engine:insuranceState.rule_engine_id,
            user_id:insuranceState.user_id,
            role_id:insuranceState.role_id,
            lead_type:insuranceState.lead_type,
            partner_id:insuranceState.partner_id,
            partner_slug:insuranceState.partner_slug,
            apptype:insuranceState.application_type_id,
            tenure:insuranceState.tenure,
            referrer_mark_up:insuranceState.referrer_mark_up,
            car_fmv:insuranceState.fmv_car,
            acc_price:insuranceState.accessories_price,
            total_fmv:insuranceState.total_fmv,
            vehicle_usage:insuranceState.vehicle_id,
            vehicle_type:insuranceState.vehicle_type_id,
            make:insuranceState.make,
            model:insuranceState.model,
            version:insuranceState.version,
            make_name:insuranceState.make_name,
            model_name:insuranceState.model_name,
            version_name:insuranceState.version_name,
            make_year:insuranceState.mfg_year,
            plate_number:insuranceState.plate_no,
            fuel_type:insuranceState.fuel_type,
            transmission:insuranceState.transmission,
            seat_capacity:insuranceState.vehicle_seat,
            name:insuranceState.customer_name,
            phone:insuranceState.mobile,
            alt_phone:insuranceState.alternate_mobile??undefined,
            dob:insuranceState.dob,
            email:insuranceState.email,
            customer_type:insuranceState.customer_type,
            payment_method:insuranceState.payment_method_id,
            copy_of_insurance:insuranceState.copy_of_insurance,
            per_house_no:insuranceState.per_house_no,
            per_barangay:insuranceState.per_barangay,
            per_street:insuranceState.per_street,
            per_city:insuranceState.per_city,
            per_region:insuranceState.per_region,
            per_postal_code:insuranceState.per_postal_code,
            cur_house_no:insuranceState.cur_house_no,
            cur_barangay:insuranceState.cur_barangay,
            cur_street:insuranceState.cur_street,
            cur_city:insuranceState.cur_city,
            cur_region:insuranceState.cur_region,
            cur_postal_code:insuranceState.cur_postal_code,
            per_sameas_cur:insuranceState.per_sameas_cur,
            insurance_exists:insuranceState.insurance_exists,
            car_under_finance:+insuranceState.is_under_financing,
            ucf_lead_id:(insuranceState.ucf_lead_id)?insuranceState.ucf_lead_id:0,
            payment_tenure: insuranceState.payment_tenure_id || 0,
            mv_number:insuranceState.mv_number,
            referrer_name:insuranceState?.referrer_name,
            referrer_mobile:insuranceState?.referrer_mobile,
            other_detail_dealer_id:insuranceState?.other_detail_dealer_id,
            lead_sub_source: insuranceState?.lead_sub_source || null

        }
        if(insuranceState.application_type_id===1){
            params.dealer_id=insuranceState.user_id;
        }

        if(insuranceState.dealer_name){
            params.dealer_name=insuranceState.dealer_name;
        }
        if(+insuranceState.is_under_financing===1){
            params.car_under_finance=+insuranceState.is_under_financing;
            params.finance_from_date=insuranceState.financing_from_date;
            params.finance_to_date=insuranceState.finance_to_date;
            params.financier=insuranceState.financier_id;
            params.financier_name=insuranceState.financier_name;
        }
        if(insuranceState.insurance_exists===1){
            params.insurer_name=insuranceState.insurer_name;
        }
        if(insuranceState.color){
            params.color=insuranceState.color;
        }
        if(insuranceState.chassis_number){
            params.chassis_number=insuranceState.chassis_number;
        }
        if(insuranceState.machine_number){
            params.machine_number=insuranceState.machine_number;
        }
        if(insuranceState.accessories_description){
            params.acc_description=insuranceState.accessories_description;
        }
        if([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState.application_type_id) && (insuranceState.discount || loanQuote?.discount)){
            params.discount= (loanQuote?.discount || loanQuote?.discount === 0) ? loanQuote.discount : insuranceState.discount;
        }

        if(![APP_TYPE_AGENCY].includes(insuranceState.application_type_id) ){
            params['payment_term'] = insuranceState.payment_term_id

        }

        params.net_premium =  loanQuote?.net_premium || insuranceState.net_premium;
        params.add_on_rate = loanQuote?.base_issue_rate || insuranceState.add_on_rate
        params.referrer_mark_up = (loanQuote?.referrer_mark_up || loanQuote?.referrer_mark_up === 0) ? loanQuote?.referrer_mark_up : insuranceState.referrer_mark_up;

        let addon_detail = (addonList.length)?  getAddonList([],addonList) : insuranceState?.addon_detail;    
        params={...params,...leadDetails,...{addon_detail}};
        setLoading(true)
        // Loop to filter out properties with null values
        for (const key in params) {
            if (params[key] === null) {
                delete params[key];
            }
        }
        if(+params.car_under_finance===2){
            delete params.financier;
            delete params.finance_to_date;
            delete params.finance_from_date;
        }
        if(+params.insurance_exists===2){
            delete params.insurer_name;
            delete params.insurance_expire_date;
        }
        // add Lead Source and Other documents
        params.lead_source =insuranceState?.insurance_lead_source || 0
        params.other_doc = ((insuranceState?.other_doc && insuranceState?.other_doc?.length && typeof insuranceState?.other_doc === 'string') ? insuranceState?.other_doc.split(',') : insuranceState?.other_doc ) || undefined 
        
        if(insuranceState?.application_type_id === APP_TYPE_AGENCY) {
            params['per_full_address'] = insuranceState?.per_full_address
            params['cur_full_address'] = insuranceState?.cur_full_address
            params['agency_id'] = insuranceState?.agency_id
            params['paid_from_agency'] = 0

            let keysToDelete = ['referrer_mark_up', 'ucf_lead_id', 'customer_type', 'lead_source', 'finance_from_date', 'finance_till_date', "insurance_exists", "payment_method", "paid_from_agency", "per_house_no", "per_barangay", "per_street", "per_city", "per_region", "per_postal_code", "cur_house_no", "cur_barangay", "cur_street", "cur_city", "cur_region", "cur_postal_code"]

            keysToDelete.forEach((key: string) => {
                delete params[key]
            })
        }
        
        const fields = ENCRYPTED_FIELDS;
        fields.forEach(field => {
            if (params?.[field]) {
                params[field] = HELPER.EncodetoBase64(params[field]);
            }
        });
        let result: any = insuranceState?.application_type_id === APP_TYPE_AGENCY ? await AgencyService.createAgencyLead(params) : await InsuranceService.createLead(params);
        setLoading(false)
        if (result?.data?.data) {
            toast.success("Insurance updated successfully");
            //dispatch(getInsuranceDetails({ "leadId": queryParams.id }));
            fetchLeadData({ "lead_id": queryParams.id, apiType: API_TYPES['customerData'] }).then((res:any)=>{
                if (res && res.status === 200) {
                    //insuranceState
                    let leadDetails = allState?.insurance?.leadDetail
                    let updatedLeadDetails = {
                        ...leadDetails,
                        ...res.data,                   
                        quotes:  {
                            ...leadDetails.quotes,
                            ...res?.data?.quotes
                        }
                    };
                    if(leadDetails?.status_id <= STATUS_SUB_STATUS['status']['sent_to_insurance_broker'] ){

                        dispatch(getLeadEmailTemplates({ partner_id: +leadDetails?.partner_id, leadId: leadDetails?.leadId }))
                        
                    }
                    dispatch(updateLeadDetailsInStore((updatedLeadDetails)))
                            
                    

                }
            });
            
            setLeadDetails({});
            setFormData([]);
            setLoanQuote(null)
            // updateLeadDetailsInStore({...leadDetails})
            setShowApproveBtn(false); 
        }else{
            toast.error(result?.data?.message);
        }
    }

    const parentchangeHandler =(params:any)=>{
        calculateInsurance(formData,params);    
    }  

    const handleDownloadPdfClick = async () => {
        setLoading(true)
        let params = { lead_id: insuranceState?.hashId }
        let pdfResponse: any = await downloadLoanQuotationPdf(params)
        if (pdfResponse && pdfResponse.status && pdfResponse.status === 200 && pdfResponse.data && pdfResponse.data.link) {
            window.open(pdfResponse.data.link, '_blank')
        }
        setLoading(false)
    } 

    const onValueChange = (fieldItem:any) => { 
        if(fieldItem.name === "car_under_finance"){
            setFinanceType(+fieldItem.value.value);
            SetActNature();
        }else if(fieldItem.name === "insurance_type"){
            setInsuranceType(fieldItem.value.value);
            fetchAddons(fieldItem.value.value);
        }else if(fieldItem.name === "vehicle_type"){
            setVehicleType(fieldItem.value.value);
        }
        else if(fieldItem.name === "vehicle_usage"){
            setVehicleUsage(fieldItem.value.value);
        }  
    }

    const SetActNature = async () => {
        let is_required=false;
        if(insuranceType ===1 && +financeType===1){
            is_required=true;
        }
        let originalList:any = addonList;
        if(originalList.length>0){
            originalList=originalList.map((ob:any)=>{
                return {
                    ...ob,is_required:(ob.id===6)?is_required:ob.is_required,
                    is_selected:(ob.id===6)?is_required:ob.is_selected
                }
            })
            setAddonList([...originalList]);
            setValue(value + 1)
        }
    }  

    const handleAddonPopupModal = (action: any) => {
        fetchAddons(); 
        showHideAddons(false)
    }

    return (
        <div className=" laon-expect-outer">
            <FullPageLoader show={loading} />
            <div className="loan-expect-left-panle">
                {/* defuat step start  */}
                {displayBlock === 'SUMMARY' ?
                    <div className="loan-form">
                        <div className="heading-top">
                            <span>Insurance Calculator</span>
                            <div className="action-icn">
                                {
                                  insuranceState && insuranceState.leadId && insuranceState?.doc_status_id>1 &&   
                                    <ul>
                                        {insuranceState?.sub_status_id < STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'] && HELPER.isUserHasUpdateAccess({ permissionID: ROLE_FEATURES['loan_expected'], accessType: 'edit', leadDetails: insuranceState, pageSlug:"customer_details" }) && insuranceState?.sub_status_id !== STATUS_SUB_STATUS['subStatus']['approval_in_progress'] && <li onClick={toggleswitch.bind(this, 'EDITVIEW')}>

                                                <i className="ic-createmode_editedit"></i>

                                        </li>}
                                        <li onClick={handleDownloadPdfClick}> 
                                            <i className="ic-save_alt"></i> 
                                        </li>
                                        <li onClick={showModalFinancerEmail}> 
                                            <i className="ic-share"></i> 
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                       <QuotesDetail insuranceType={insuranceType} leadDetails={insuranceState} showApproveBtn={showApproveBtn} detail={(loanQuote)?loanQuote:insuranceQuotesState} saveInsurance={saveInsurance} parentchangeHandler={parentchangeHandler} />
                    </div>
                    : null}
                {/* end  */}


                {/* loan edit step  */}
                {displayBlock === 'EDITVIEW' ?
                    <div className="loan-form">
                        <div className="heading-top">
                            <span>Insurance Calculator</span>
                            <div className="action-icn">
                                <ul>
                                    {/* <li><i className="ic-save_alt"></i></li>
                                    <li><i className="ic-share"></i></li> */}
                                </ul>
                            </div>
                        </div>

                       <InsuranceEditForm addonCount={addonCount} showHideAddons={showHideAddons} toggleswitch={(e:any) => toggleswitch(e)} calculateInsurance={calculateInsurance} leadDetails={insuranceState} insuranceType={insuranceType} onValueChange={onValueChange}/>
                    </div>
                    : null}
                {/* end */}
            </div>

            <div className="loan-expect-right-panel">
                <LoanExpectedRightPanel vehicleTypeList={vehicleTypeList}/>
            </div>

            <div className="view-timeline-popup addon-modal">
                {/* <Modal show={addons} handleClose={showHideAddons.bind(this, false)} > */}
                <Modal show={addons} handleClose={handleAddonPopupModal} >
                    <AddonForm setAddon={setAddon} addonList={addonList} addonCount={addonCount} insuranceState={insuranceState} insuranceType={insuranceType} showHideAddons={showHideAddons} />
                </Modal>
            </div>

            {
                showShareLoanQuote ?
                    <ShareQuotesDetail show={showShareLoanQuote} handleClose={showModalFinancerEmail} setLoader={setLoading}/>
                    : null
            }

        </div>

    )
}

export default LoanExpected;